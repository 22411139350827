import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentDiscovery = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Discovery Services</span>
                            <h3>About</h3>
                            <p>Most of the data scientist's day is not spent training the machine learning models, but finding and preparing the data. We help to organize the data in this process with best practices and tools.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Data Catalog</li>
                                            <li>Lineage</li>
                                            <li>Documentation</li>
                                            <li>Profiling</li>
                                            <li>Quality</li>
                                            <li>Sampling</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>The process of Data Discovery involves collecting and evaluating data from various sources across multiple platforms in order to identify trends and patterns in the data. Starting with profiling for simple trends it gets enriched by matching the results across classifications and hierarchies within Reference Data Management and then being associated with Metadata Management to get business insights, it offers support of any Analytics processes following.</p>
                            <p>It is also used for GDPR purposes in order to identify sensitive data and apply anonymization algorithms or generate valid testing data.</p>
                            <p>We support all data discovery actions starting from documenting the business terms, current architecture, physical assets identification, business to physical relationships, reference data management, simple and enhanced profiling of data, association of profiling results with various trends markers, generation of test data management frameworks, patterns matching, anonymization until analytics like machine learning models, their training and deployment.</p>
                            <p>The entire topic of data discovery is governed throughout a Life Cycle, and we help in linking dependent processes to changes criteria. This means that apart from the data changes, a scalable solution and framework is necessary in order to easily react and adapt to changes.</p>
                            <p>You can benefit out of our experience with complex architectures and requirements, supported by the best tooling on the market, in order to get the best and most out of your data.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentDiscovery